$component-content-background-color: white;
$component-content-2-padding: $desktop-padding;

.premier-content-1{
  // Insert content option 1 css here, if needed
}

.premier-content-2 {
  &.home.user_mode,
  &#home.user_mode{
    .yieldPageContent {
      .layoutContainer{
        background-color: transparent;
      }
      > .layoutContainer > .column > .pageEl{ // This selector is specific to avoid cascading into tab elements
        padding-left: $component-content-2-padding;
        padding-right: $component-content-2-padding;
      }
      .column{
        background-color: $component-content-background-color;
        background-clip: content-box;
        min-height: auto;
      }
    }
    #panelTwo:before{
      display: none;
    }
  }
}

.premier-content-3{
  &.home.user_mode,
  &#home.user_mode {
    .siteContainer{
      padding-top: 0;
      padding-bottom: 0;
    }
    #panelTwo{
      background-color: $component-content-background-color;
    }
  }
}
