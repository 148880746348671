.compact{
  .pageElement{
    margin-top: 0;
    margin-bottom: 0;
  }
}

.collapse-padding{
  &.layoutContainer
  .column{
    padding-left: 0;
    padding-right: 0;
  }
}