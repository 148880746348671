$slick-font-family: "fontawesome" !default;
$slick-arrow-color: $color-gray-md !default;
$slick-arrow-color-hover: $color-gray-dk !default;
$slick-arrow-color-inactive: $color-gray !default;
$slick-dot-color: $color-gray-md !default;
$slick-dot-color-active: $color-gray-dk !default;
$slick-prev-character: "\f053" !default;
$slick-next-character: "\f054" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

/* Slider */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    outline: none;
    background: transparent;
    color: transparent;
  }
  &:before {
    font-family: $slick-font-family;
    font-size: 20px;
    line-height: 1;
    color: $slick-arrow-color;
    transform: color $transition-linear;
  }
  &:hover,
  &:focus {
    &:before {
      color: $slick-arrow-color-hover;
    }
  }
  &.slick-disabled:before {
    color: $slick-arrow-color-inactive;
  }
}

.slick-prev {
  left: -25px;
  [dir="rtl"] & {
    left: auto;
    right: -25px;
  }
  &:before {
    content: $slick-prev-character;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: -25px;
  [dir="rtl"] & {
    left: -25px;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}