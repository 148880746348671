// Config
// Global Layout
// Page Manager
// Page Title


// Colors
$color-primary: #ff7900; // #4A90E2;
$color-primary-dk: darken($color-primary, 5%);

$color-secondary: #940f2f; //#BD10E0;
$color-secondary-dk: darken($color-secondary, 5%);

$color-black: #333;
$color-gray-dk: #434C51;
$color-gray-md: #999;
$color-gray: #CCC;
$color-gray-lt: #EEE;

$contrast-text-color-light: white;
$contrast-text-color-dark: $color-gray-dk;

$premier-media-path: "https://assets.ngin.com/site_files/_themes/premier/media/";
@import "base/utilities/helper_functions";
@import "base/utilities/helper_mixins";

// Fonts
$font-primary: 'Source Sans Pro';
$font-secondary: 'Lato';
@include import-google-fonts;

$enable-global-font-smoothing: true;

$transition-standard: .2s ease-in;
$transition-linear: .2s linear;

$global-ui-border-size: 1px;
$global-ui-border-color: #D8D8D8;


// Variables
$desktop-padding: 15px;
$mobile-padding: 10px;

$content-area-width: 1200px;
$content-area-padding: ($desktop-padding * 2);
$content-area-padding-mobile: ($mobile-padding * 2);
$content-area-background: #FFF;

$content-container-margin: 30px;
$enable-full-width-layout: true;
$include-page-title-in-content-area-background: true;

$breakpoint-sm-max: 767px;
$breakpoint-md-min: 768px;
$breakpoint-intermediate-min: 768px;
$breakpoint-intermediate-max: 999px;

// UI Elements
$global-ui-border-size: 1px;
$global-ui-border-color: gainsboro;

// Global Layout
@mixin adjust-margin-x($multiplier: 1, $responsive: true){
  margin-left: $desktop-padding * $multiplier;
  margin-right: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max){
      margin-left: $mobile-padding * $multiplier;
      margin-right: $mobile-padding * $multiplier;
    }
  }
}
@mixin page-element-padding(){
  padding-top: $desktop-padding;
  padding-bottom: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-top: $mobile-padding;
    padding-bottom: $mobile-padding;
  }
}
@mixin page-element-margin(){
  margin-top: $desktop-padding;
  margin-bottom: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    margin-top: $mobile-padding;
    margin-bottom: $mobile-padding;
  }
}
@mixin column-padding(){
  padding-left: $desktop-padding;
  padding-right: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }
}
@mixin content-container-padding($multiplier: 1, $responsive: true){
  padding-left: $desktop-padding * $multiplier;
  padding-right: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max){
      padding-left: $mobile-padding * $multiplier;
      padding-right: $mobile-padding * $multiplier;
    }
  }
}
@mixin content-container-padding-vert($multiplier: 1, $responsive: true){
  padding-top: $desktop-padding * $multiplier;
  padding-bottom: $desktop-padding * $multiplier;
  @if $responsive == true {
    @media screen and (max-width: $breakpoint-sm-max){
      padding-top: $mobile-padding * $multiplier;
      padding-bottom: $mobile-padding * $multiplier;
    }
  }
}
@mixin content-area-width{
  width: 100%;
  max-width: $content-area-width;
  margin-left: auto;
  margin-right: auto;
}
@mixin content-area-container-style{
  background-color: $content-area-background;
  box-shadow: 0px 2px 9px rgba(black, .05);
}
@mixin global-link-style{
  background: $color-primary;
  transition: background-color $transition-linear;
  -webkit-appearance: none;
  text-decoration: none;
  color: choose-contrast-color($color-primary);
  &:focus,
  &:hover{
    background: darken($color-primary, 20%);
  }
}
@mixin global-link-container($padding: 10px){
  display: block;
  padding: $padding;
}
@mixin global-link-text($align: left){
  text-transform: uppercase;
  font-family: $font-secondary;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
  text-align: $align;
}
@mixin global-link-icon-style($align: flex-start, $color: $color-secondary, $hover: choose-contrast-color($color-primary)) {
  display: flex;
  flex-flow: row;
  justify-content: $align;
  align-items: center;
  &:after{
    color: $color;
    display: inline-block;
    margin-left: 1ch;
    transition: color $transition-linear;
  }
  &:hover:after{
    color: $hover;
  }
}
@mixin global-link-icon-character($character: "\f0da", $font: FontAwesome){
  &:after{
    content: $character;
    font-family: $font;
  }
}


// Page Element Header
@mixin page-element-header() {
  font-family: $font-secondary;
  font-size: 26px;
  line-height: 1.125;
  font-weight: 700;
  text-transform: none;
  color: $color-primary;
}


// Page Manager
$page-manager-width: 60px;

// Page Title
$page-title-full-width: true;
$page-title-full-width-text: false;
$page-title-container-background: $content-area-background;
$page-title-text-color: $color-primary;
$page-title-font-size: 40px;
$page-title-text-case: uppercase;
$page-title-font-weight: bold;
$page-title-text-align: null;

@mixin page-title-container{
  // site page title container css here
  border-bottom: $global-ui-border-size solid $global-ui-border-color;
  padding: 0;
}
@mixin page-title-text{
  // site page title text css here
  font-family: $font-secondary;
}
@mixin page-title-image-container{
  // site page title w/image container css here
  background: none !important;
}

// Account Navigation Options
$account-nav-bottom: false;
$account-nav-mobile-bottom: true;
$account-nav-background: #23282b;
$account-nav-color: #FFF;
$account-nav-secondary-color: $account-nav-color;
$account-nav-secondary-color-hover: darken($account-nav-color, 20%);
$account-nav-font-size: 14px;
$account-nav-height-desktop: 40px;
$account-nav-height-mobile: 44px;
$account-nav-z-index: 399;
$account-nav-icon-width: 32px;

$site-tagline-spacing: 15px; // Need this for "_source/sass/_navigation/_network_nav_bar.scss" —— Try to phase this out


// General Navigation
$pages-hidden-in-user-mode: null; // Example: "teams", "about us", "home";

$premier-navigation-option: 2;

// Premier
$global-header-cta-background-color: $color-primary;
$global-header-cta-background-color-hover: $color-primary-dk;
$global-header-cta-text-color: choose-contrast-color($global-header-cta-background-color);
$global-header-cta-text-color-hover: choose-contrast-color($global-header-cta-background-color-hover);
$global-header-cta-border-radius: 0px;
$global-header-cta-border-size: 0px;
$global-header-cta-border-color: rgba($global-header-cta-text-color, .3);

$global-header-social-background-color: $color-primary;
$global-header-social-background-color-hover: $color-primary-dk;
$global-header-social-text-color: choose-contrast-color($global-header-social-background-color);
$global-header-social-text-color-hover: choose-contrast-color($global-header-social-background-color-hover);
$global-header-social-border-radius: 100%;

@mixin global-cta() {
  .call-to-action {
    .linkElement h4{
      text-align: center;
    }
    .linkElement h4 a {
      &:after {
        display: none;
      }
      display: inline-block;
      background-color: $global-header-cta-background-color;
      color: $global-header-cta-text-color;
      border-style: solid;
      border-width: $global-header-cta-border-size;
      border-color: $global-header-cta-border-color;
      border-radius: $global-header-cta-border-radius;
      font-weight: bold;
      font-size: 14px;
      padding: 10px 30px;
      &:hover {
        background-color: $global-header-cta-background-color-hover;
        color: $global-header-cta-text-color-hover;
      }
    }
  }
}
@mixin global-cta-image() {
  .call-to-action {
    .heroPhotoElement{
      max-width: 120px;
      br, .cutline{
        display: none;
      }
      img {
        max-height: 40px;
        height: 100%;
      }
      [src$=".svg"]{
        height: 40px;
      }
    }
  }
}
@mixin global-social($square:'false'){
  .social-media-links {
    .sn-social-media-list{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sn-social-media-list .sn-social-media-icon {
      margin: 5px;
      background-color: $global-header-social-background-color;
      color: $global-header-social-text-color;
      border-radius: $global-header-social-border-radius;
      &:hover {
        opacity: 1;
        background-color: $global-header-social-background-color-hover !important;
        color: $global-header-social-text-color-hover;
      }
    }
  }
  @if $square == 'true' {
    .social-media-links .sn-social-media-list .sn-social-media-icon {
      border-radius: 0;
      height: $nav-title-bar-height;
    }
  }
}

// Mobile Nav Bar
$mobile-nav-background-color: white;
$mobile-nav-toggle-right: true;
$mobile-nav-toggle-width: 44px;
$mobile-nav-toggle-height: 44px;
$mobile-nav-toggle-position: center;
$mobile-nav-open-toggle-position-default: true;
$mobile-nav-height: 44px;
$mobile-nav-align: center;
$mobile-nav-justification: center;

@import "_config_header_2";

// Footer Colophon
$footer-colophon-se-logo-black-color: true;
$footer-colophon-se-logo-white-color: false;
$footer-colophon-se-logo-white: false;
$footer-colophon-max-width: $content-area-width;
$footer-colophon-band-background-color: $color-gray-lt;
$footer-colophon-content-background-color: transparent;
$footer-colophon-item-padding: 10px;
$footer-colophon-flex-justification: center;
$footer-colophon-flex-align: center;
$footer-colophon-font-size: 14px;
$footer-colophon-line-height: 1.75;
$footer-colophon-text-case: none;
$footer-colophon-text-color: choose-contrast-color($footer-colophon-band-background-color);
$footer-colophon-link-color: $footer-colophon-text-color;
$footer-colophon-link-color-hover: $color-primary;


// Site Background
$site-background-image-full-height: false;


// Page Banner
$site-banner-full-width: true;
$site-banner-full-width-text: false;

$site-banner-padding: 15px;
$site-banner-font-size: 20px;
$site-banner-font-size-min: 20px;
$site-banner-font-size-max: 72px;
$site-banner-text-color: white;
$site-banner-font-family: null;
$site-banner-line-height: 1;
$site-banner-font-weight: normal;
$site-banner-text-case: null;
$site-banner-text-align: center;

@mixin site-banner-container{
  // site banner container css here
}

$site-banner-headline-text-color: inherit;
$site-banner-headline-font-size: null;
$site-banner-headline-font-family: $font-primary;
$site-banner-headline-line-height: null;
$site-banner-headline-font-weight: null;
$site-banner-headline-text-case: null;
$site-banner-headline-text-align: null;
$site-banner-title-text-color: inherit;
$site-banner-title-font-size: inherit;
$site-banner-title-font-family: $font-secondary;
$site-banner-title-line-height: null;
$site-banner-title-font-weight: inherit;
$site-banner-title-text-case: null;
$site-banner-title-text-align: null;
$site-banner-tagline-text-color: inherit;
$site-banner-tagline-font-size: null;
$site-banner-tagline-font-family: $font-primary;
$site-banner-tagline-line-height: null;
$site-banner-tagline-font-weight: null;
$site-banner-tagline-text-case: null;
$site-banner-tagline-text-align: null;


// Element Options
$element-options-flex-justification: flex-end;
$element-options-font-size: 12px;
$element-options-text-wieght: 600;
$element-options-text-color: $color-gray-dk;
$element-options-text-color-hover: $color-primary;
$element-options-font-family: $font-primary;
$element-options-icon-margin: .7ch;
$element-options-icon-text-space: 1.5ch;


// Tabs
$element-tabs-item-padding: 12px 12px;
$element-tabs-item-background-color: $color-secondary;
$element-tabs-item-background-color-hover: $color-primary;
$element-tabs-item-text-color: choose-contrast-color($element-tabs-item-background-color);
$element-tabs-item-text-color-hover: choose-contrast-color($element-tabs-item-background-color-hover);
$element-tabs-item-text-case: uppercase;
$element-tabs-item-font-family: $font-secondary;
$element-tabs-item-font-weight: 700;
$element-tabs-item-font-size: 14px;
$element-tabs-item-text-line-height: 1.2;


// Content Typography (WYSIWYG editor content)
@mixin content-typography-headers(){
  h1, h2, h3, h4, h5, h6{
    font-family: $font-secondary;
  }
  h1{
    font-size: 44px;
    line-height: 1.125;
    font-weight: 900;
    color: $color-primary;
  }
  h2{
    font-size: 30px;
    line-height: 1.25;
    font-weight: 900;
    color: $color-primary;
  }
  h3{
    font-size: 24px;
    line-height: 1.125;
    font-weight: 400;
    color: $color-primary;
  }
  h4{
    font-size: 21px;
    line-height: 1.125;
    font-weight: 900;
    color: $color-black;
  }
  h5{
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
    color: $color-black;
  }
  h6{
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    color: $color-black;
  }
}

@mixin content-typography-anchors(){
  a {
    color: $color-primary;
    font-weight: 600;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

@mixin content-typography-blockquote(){
  blockquote {
    font-weight: 400;
    font-size: 24px;
    color: $color-secondary;
    margin-bottom: 1rem;
  }
}

@mixin content-typography-other(){
  // catchall for anything else you need
}

// Legacy Itasca Table Variables
// Used elsewhere: Calendar, event aggs, etc...
$table-body-background: #fff; // legacy, not used in base tables
$table-cell-spacing: 2px; // legacy, not used in base tables

// Core Table Padding (needs a bit more refactoring)
$core-table-header-padding-vertical: 3px;
$core-table-header-padding-horizontal: 12px;
$core-table-cell-padding-vertical: 2px;
$core-table-cell-padding-horizontal: 12px;

// Core Table Borders
$core-table-cell-border-size: 2px;
$core-table-cell-border-color: white;
$core-table-cell-border-width: 2px;
$core-table-cell-border: 0 $core-table-cell-border-width $core-table-cell-border-width 0;
$core-table-body-border-left-width: 0px;
$core-table-body-border-right-width: 0px;
$core-table-body-border-top-width: 0px;
$core-table-body-border-bottom-width: 0px;

$core-table-header-border-color: white;
$core-table-header-border-width: 2px;
$core-table-header-border: 0 $core-table-header-border-width $core-table-header-border-width 0;
$core-table-header-border-left-width: 0px;
$core-table-header-border-right-width: 0px;
$core-table-header-border-top-width: 0px;
$core-table-header-border-bottom-width: $core-table-header-border-width;

// Core Table Body Layout
$core-table-body-min-height: 30px;
$core-table-body-background-color: $color-gray-lt;
$core-table-body-background-color-odd: $core-table-body-background-color;
$core-table-body-background-color-even: $core-table-body-background-color;
$core-table-body-hightlight-color-odd: darken($core-table-body-background-color, 3%);
$core-table-body-hightlight-color-even: darken($core-table-body-background-color, 3%);

// Core Table Body Typography
$core-table-body-font-family: null;
$core-table-body-font-size: 12px;
$core-table-body-line-height: 1.2;
$core-table-body-font-weight: normal;
$core-table-body-font-color: choose-contrast-color($core-table-body-background-color);

// Core Table Header Layout
$core-table-header-min-height: 30px;
$core-table-header-background-color: $color-primary;
$core-table-header-hightlight-color: darken($core-table-header-background-color, 5%);

// Core Table Header Typography
$core-table-header-font-family: $font-primary;
$core-table-header-font-size: 12px;
$core-table-header-line-height: 1.2;
$core-table-header-font-weight: bold;
$core-table-header-font-color: choose-contrast-color($core-table-header-background-color);
$core-table-header-text-transform: uppercase;

// Core Table Sorting
$core-table-sort-hover-color: rgba($core-table-header-font-color, .7);
$core-table-sort-icon-default: '\f0dc';
$core-table-sort-icon-up: '\f0de';
$core-table-sort-icon-down: '\f0dd';
$core-table-sort-icon-size: $core-table-header-font-size;
$core-table-sort-icon-padding: $core-table-sort-icon-size+4px;
$core-table-sort-icon-offset: $core-table-header-padding-horizontal+$core-table-sort-icon-padding;


// Page Banners
$site-banner-overlay-gradient-color: null; // black, $color-primary, or $color-secondary
$site-banner-fixed-min-height: null; // 42.857vw

// Mixins/Functions
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@function aspect-to-percent($target, $container) {
  @return ($container / $target) * 100%;
}