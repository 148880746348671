$component-cta-2-overlay-color: $color-primary;
$component-cta-2-overlay-color-bottom: darken($component-cta-2-overlay-color, 5%);

$component-cta-2-accent-background-color: $color-secondary;
$component-cta-2-accent-background-color-rgb: color-to-rgb($component-cta-2-accent-background-color);
$component-cta-2-accent-text-color: choose-contrast-color($component-cta-2-accent-background-color);

$component-cta-2-accent-background-size: $cta-element-overlay-padding * 2;
$component-cta-2-accent-background-size-hover: $cta-element-overlay-padding * 3;
$component-cta-2-accent-icon-size: $cta-element-overlay-padding * 1.25;
$component-cta-2-accent-icon-size-hover: $cta-element-overlay-padding * 1.875;

$component-cta-2-text-color: choose-contrast-color($component-cta-2-overlay-color);

.premier-cta-option-2 {
  &.sn-variation-default {
    .sn-call-to-action-title{
      color: $component-cta-2-text-color;
    }
    .sn-call-to-action-subtitle{
      span, a{
        color: $component-cta-2-text-color;
      }
    }
    align-items: center;
    position: relative;
    min-height: 120px;
    background-color: $component-cta-2-overlay-color;
    background-image: linear-gradient(to top, $component-cta-2-overlay-color-bottom 50%, transparent 50%);

    .aspect-element,
    img {
      display: none;
    }

    .sn-call-to-action-overlay {
      position: static;

      &:before,
      &:after {
        position: absolute;
        bottom: 0;
        right: 0;
        transition:
          width $transition-linear,
          height $transition-linear;
      }

      &:after {
        content: "\f0da";
        display: flex;
        justify-content: center;
        align-items: center;
        color: $component-cta-2-accent-text-color;
        line-height: 1;
        font-family: fontawesome;
        width: $component-cta-2-accent-icon-size;
        height: $component-cta-2-accent-icon-size;
      }

      &:before {
        content: "";
        background-image: inline-css-shapes($fill: $component-cta-2-accent-background-color);
        width: $component-cta-2-accent-background-size;
        height: $component-cta-2-accent-background-size;
      }
    }

    &:hover .sn-call-to-action-overlay {
      &:after {
        width: $component-cta-2-accent-icon-size-hover;
        height: $component-cta-2-accent-icon-size-hover;
      }

      &:before {
        width: $component-cta-2-accent-background-size-hover;
        height: $component-cta-2-accent-background-size-hover;
      }

    }
  }
}



