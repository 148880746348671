$media-slider-el-slide-title-margin: 15px 0;
$media-slider-el-slide-title-font: $font-secondary;
$media-slider-el-slide-title-text-size: 48px;
$media-slider-el-slide-title-text-size-small: 24px;
$media-slider-el-slide-title-text-wieght: 600;
$media-slider-el-slide-title-text-case: null;
$media-slider-el-slide-title-line-height: 1;
$media-slider-el-slide-title-text-color: white;

$media-slider-el-slide-description-margin: 15px 0;
$media-slider-el-slide-description-font: $font-primary;
$media-slider-el-slide-description-text-size: 28px;
$media-slider-el-slide-description-text-size-small: 19px;
$media-slider-el-slide-description-text-wieght: 500;
$media-slider-el-slide-description-text-case: null;
$media-slider-el-slide-description-line-height: 1.2;
$media-slider-el-slide-description-text-color: white;

$media-slider-el-slider-background: black;
$media-slider-el-slider-max-width: $content-area-width;
$media-slider-el-slider-text-shadow: 1px 1px 1px rgba(black,.8);


$media-slider-el-slide-padding: 5%;

$media-slider-el-pagination-height: 32px;
$media-slider-el-pagination-width: 100%;
$media-slider-el-pagination-item-spacing: 2px;
$media-slider-el-pagination-item-width: 13px;
$media-slider-el-pagination-item-height: 13px;
$media-slider-el-pagination-item-dash-width: 28px;
$media-slider-el-pagination-item-dash-height: $media-slider-el-pagination-item-height;
$media-slider-el-pagination-item-background-default: $color-primary;
$media-slider-el-pagination-item-background-hover: $color-secondary;
$media-slider-el-pagination-item-background-active: $color-secondary;

$media-slider-el-direction-width: $media-slider-el-slide-padding;
$media-slider-el-direction-text-size: 60px;
$media-slider-el-direction-text-size-medium: 45px;
$media-slider-el-direction-text-size-small: 30px;
$media-slider-el-direction-text-color: white;
$media-slider-el-direction-icon-prev: '\f104';
$media-slider-el-direction-icon-next: '\f105';


// Slider
.mediaSlider > h2{
  @include page-element-header;
}
.mediaSlider > h2:empty,
.mediaSlider > p:empty{
  display: none;
}
.sn-media-slider .slider {
  background: $media-slider-el-slider-background;
}
.sn-media-slider .slides .slide .slide-overlay {
  color: initial;
  padding: $media-slider-el-slide-padding;
}


// Pagination
.sn-media-slider .slider-pagination {
  height: $media-slider-el-pagination-height;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.sn-media-slider .slider-pagination .paging-item {
  height: $media-slider-el-pagination-item-height;
  width: $media-slider-el-pagination-item-width;
  margin: $media-slider-el-pagination-item-spacing;
  background: $media-slider-el-pagination-item-background-default;
}
.sn-media-slider .slider-pagination .paging-item:hover {
  background: $media-slider-el-pagination-item-background-hover;
}
.sn-media-slider .slider-pagination .paging-item.flex-active {
  background: $media-slider-el-pagination-item-background-active;
}
.sn-media-slider .slider[class^="top-"] {
  margin: $media-slider-el-pagination-height 0 0;
}
.sn-media-slider .slider[class^="bottom-"] {
  margin: 0 0 $media-slider-el-pagination-height;
}

.sn-media-slider .slider[class^="bottom-"] .slider-pagination { bottom: 0; }
.sn-media-slider .slider[class^="top-"] .slider-pagination { top: 0; }
.sn-media-slider .slider[class*="-center-paging"] .slider-pagination { justify-content: center; }
.sn-media-slider .slider[class*="-right-paging"] .slider-pagination { justify-content: flex-end; }
.sn-media-slider .slider[class*="-left-paging"] .slider-pagination { justify-content: flex-start; }

.sn-media-slider .slider-pagination.dashed .paging-item {
  width: $media-slider-el-pagination-item-dash-width;
  height: $media-slider-el-pagination-item-dash-height;
}


// Text & Text Alignment
.sn-media-slider .slides .slide .slide-overlay{
  width: 100%;
  max-width: $media-slider-el-slider-max-width;
  left: 50%;
  transform: translateX(-50%);
}
.sn-media-slider .slider .slide-text[class*="-left"] { text-align: left;}
.sn-media-slider .slider .slide-text[class*="-center"] { text-align: center;}
.sn-media-slider .slider .slide-text[class*="-right"] { text-align: right;}
.sn-media-slider .slider .slide-text[class*="bottom-"] { vertical-align: bottom;}
.sn-media-slider .slider .slide-text[class*="middle-"] { vertical-align: middle;}
.sn-media-slider .slider .slide-text[class*="top-"] { vertical-align: top;}

.sn-media-slider .slider .slide-title {
  margin: $media-slider-el-slide-title-margin;
  text-shadow: $media-slider-el-slider-text-shadow;
  font-family: $media-slider-el-slide-title-font;
  text-transform: $media-slider-el-slide-title-text-case;
  font-weight: $media-slider-el-slide-title-text-wieght;
  font-size: $media-slider-el-slide-title-text-size;
  line-height: $media-slider-el-slide-title-line-height;
  color: $media-slider-el-slide-title-text-color;
}
.sn-media-slider .slider .slide-description {
  margin: $media-slider-el-slide-description-margin;
  text-shadow: $media-slider-el-slider-text-shadow;
  font-family: $media-slider-el-slide-description-font;
  text-transform: $media-slider-el-slide-description-text-case;
  color: $media-slider-el-slide-description-text-color;
  font-size: $media-slider-el-slide-description-text-size;
  font-weight: $media-slider-el-slide-description-text-wieght;
  line-height: $media-slider-el-slide-description-line-height;
}
.sn-media-slider .slider.sn-breakpoint-lt-500{
 .slide-title {
   font-size: $media-slider-el-slide-title-text-size-small;
  }
  .slide-description { 
    font-size: $media-slider-el-slide-description-text-size-small;
  }
}
.sn-media-slider .slider.sn-breakpoint-lt-300{
  .slide-title {
    font-size: $media-slider-el-slide-description-text-size-small;
  }
  .slide-description {
    display: none;
  }
}


// Directional Navigation
.sn-media-slider{
  .flex-direction-nav{
    max-width: $media-slider-el-slider-max-width;
    pointer-events: none;
    margin: auto;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    .flex-nav-prev,
    .flex-nav-next{
      pointer-events: initial;
      left: auto !important;
      right: auto !important;
      width: $media-slider-el-direction-width;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .slider[class*="top-"] .flex-direction-nav { padding-top: $media-slider-el-pagination-height; }
  .slider[class*="bottom-"] .flex-direction-nav { padding-bottom: $media-slider-el-pagination-height; }

  .flex-direction-nav {
    .flex-prev,
    .flex-next{
      position: relative;
      display: block;
      overflow: hidden;
      z-index: 10;
      top: auto;
      margin: 0;
      width: 100%;
      height: auto;
      line-height: 1;
      font-size: 0; // hide text
      text-align: center;
      text-decoration: none;
      opacity: 0;
      transform: initial !important;
      cursor: pointer;
      color: initial;
      text-shadow: none;
      transition: 
        opacity $transition-linear,
        left $transition-linear,
        right $transition-linear;
      &:before {
        font-size: $media-slider-el-direction-text-size;
        color: $media-slider-el-direction-text-color;
        text-shadow: $media-slider-el-slider-text-shadow;
        font-family: "FontAwesome";
        display: inline-block;
      }
    }
    .flex-prev:before{
      content: $media-slider-el-direction-icon-prev;
      left: -100%;
    }
    .flex-next:before{
      content: $media-slider-el-direction-icon-next;
      right: -100%;
    }
  }
  .slider.sn-breakpoint-lt-500 .flex-direction-nav{
    .flex-prev:before, .flex-next:before{
      font-size: $media-slider-el-direction-text-size-medium;
    }
  }
  .slider.sn-breakpoint-lt-300 .flex-direction-nav{
    .flex-prev:before, .flex-next:before{
      font-size: $media-slider-el-direction-text-size-small;
    }
  }
  &:hover .flex-direction-nav .flex-prev { opacity: 0.7; left: 0;}
  &:hover .flex-direction-nav .flex-next { opacity: 0.7; right: 0;}
  &:hover .flex-direction-nav .flex-prev:hover,
  &:hover .flex-direction-nav .flex-next:hover { opacity: 1; }
}

