$component-sponsor-image-max-height: 80px;
$component-sponsor-image-padding: 30px 15px;
$component-sponsor-arrow-width: 50px;
$component-sponsor-border-color: $color-gray;
$component-sponsor-border-size: 1px;
$svg-bug-width-fix: 400px; // This is a fix for svgs without an intrinsic width/height set on the document

.premier-sponsor-container {
  width: 100%;
  box-shadow: inset 0px $component-sponsor-border-size 0px 0px $color-gray,
  inset 0px #{-$component-sponsor-border-size} 0px 0px $color-gray;
  padding: 0 $component-sponsor-arrow-width;
}

.premier-sponsor-slider {
  .heroPhotoElement {
    padding: $component-sponsor-image-padding;
    margin: 0;
    a {
      display: block;
    }
    img {
      max-height: $component-sponsor-image-max-height;
      width: auto;
      max-width: 100%;
      margin: auto;
      transform: scale(.9);
      transition: transform $transition-linear;
      &:hover {
        transform: scale(1);
      }
    }
    img[src*=".svg"] {
      width: $svg-bug-width-fix; 
    }
  }
}

.slick-slide {
  box-shadow: inset $component-sponsor-border-size 0px 0px 0px $component-sponsor-border-color;
  &:last-child {
    box-shadow: inset $component-sponsor-border-size 0px 0px 0px $component-sponsor-border-color,
    inset #{-$component-sponsor-border-size} 0px 0px 0px $component-sponsor-border-color;
  }
}
.slick-track {
  display: flex;
  .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
  }
}
.slick-slide > div {
  display: flex;
  align-items: center;
}
.slick-prev {
  left: -$component-sponsor-arrow-width;
  box-shadow: $component-sponsor-border-size 0px 0px 0px $component-sponsor-border-color;
}
.slick-next {
  right: -$component-sponsor-arrow-width;
  box-shadow: -$component-sponsor-border-size 0px 0px 0px $component-sponsor-border-color;
}
.slick-prev,
.slick-next {
  width: $component-sponsor-arrow-width;
  height: 100%;
  z-index: 2;
}