$nav-network-background-color: darken($nav-title-bar-band-background-color, 5%);
$nav-network-text-color: choose-contrast-color($nav-title-bar-band-background-color);

$nav-network-title-text-color: choose-contrast-color($nav-title-bar-band-background-color);
$nav-network-title-font-family: $font-secondary;
$nav-network-title-font-size: 12px;
$nav-network-title-line-height: 1;
$nav-network-title-text-case: uppercase;
$nav-network-title-text-weight: normal;

$nav-network-site-background-color: $nav-title-bar-band-background-color;
$nav-network-site-background-color-hover: darken($nav-network-background-color, 5%);

$nav-network-site-text-color: $nav-network-title-text-color;
$nav-network-site-font-family: $font-secondary;
$nav-network-site-font-size: 12px;
$nav-network-site-line-height: 1;
$nav-network-site-text-case: null;
$nav-network-site-text-weight: normal;

$nav-network-title-padding: 0px 3px;
$nav-network-dropdown-title-padding: 4px 10px;
$nav-network-site-name-padding: 5px;
$nav-network-site-logo-padding: 3px;
$nav-network-site-logo-max-height: 100%;

$nav-network-dropdown-container-shadow: 0px 5px 10px -5px #1d1d1d;
$nav-network-dropdown-item-padding: 10px;
$nav-network-dropdown-item-line-height: $dropdown-nav-line-height;
$nav-network-dropdown-item-background-color: $dropdown-nav-container-background-color;
$nav-network-dropdown-item-background-color-hover: $dropdown-nav-item-background-color-hover;

.theme-network-bar {
  padding: 0 $site-tagline-spacing;
  display: flex;
  flex-flow: row;
  align-items: center;
  white-space: nowrap;
  word-wrap: normal;
  overflow-x: hidden;
  height: 100%;

  .theme-network-bar-site-title { display: none; }

  &:not(.compact-style) {

    .theme-network-bar-title,
    .theme-network-bar-sites {
      display: flex;
      flex-flow: row;
      align-items: center;
    }
    .theme-network-bar-title {
      padding: $nav-network-title-padding;
    }
    .theme-network-bar-sites {
      height: 100%;
    }

    .theme-network-bar-site {
      display: flex;
      align-items: center;
      height: 100%;
      text-decoration: none;
      color: $nav-network-text-color;
      background-color: $nav-network-site-background-color;
      transition: background-color $transition-linear;
      &:hover{
        background-color: $nav-network-site-background-color-hover;
      }
    }

    .theme-network-bar-site-logo {
      display: block;
      width: auto;
      max-height: $nav-network-site-logo-max-height;
      padding: $nav-network-site-logo-padding;
    }

    .theme-network-bar-site-logo + .theme-network-bar-site-name {
      display: none;
    }

    .theme-network-bar-site-name {
      padding: $nav-network-site-name-padding;
      color: $nav-network-site-text-color;
      font-family: $nav-network-site-font-family;
      font-size: $nav-network-site-font-size;
      line-height: $nav-network-site-line-height;
      text-transform: $nav-network-site-text-case;
      font-weight: $nav-network-site-text-weight;
    }
  }

  &.compact-style {
    .theme-network-bar-title {
      cursor: pointer;
      line-height: 1;
      display: flex;
      padding: $nav-network-dropdown-title-padding;
      color: $nav-network-title-text-color;
      background-color: $nav-network-background-color;
      transition: background-color $transition-linear;
      &:hover {
        background-color: darken($nav-network-background-color, 5%);
      }

      &:after {
        margin-left: 5px;
        @include fa-icon;
        content: "\f107"; // dropdown arrow
      }
    }
  }
}
// Desktop
// Title
.theme-network-bar-network-title{
  color: $nav-network-title-text-color;
  font-family: $nav-network-title-font-family;
  font-size: $nav-network-title-font-size;
  line-height: $nav-network-title-line-height;
  text-transform: $nav-network-title-text-case;
  font-weight: $nav-network-title-text-weight;
}

.NGIN_tip.theme-network-bar-dropdown {
  background-color: $nav-network-background-color;
  max-width: none !important;

  .container {
    padding: 0;
    box-shadow: $nav-network-dropdown-container-shadow;
  }

  .close_tip { display: none; }

  .theme-network-bar-sites {
    display: block;
    max-height: calc(100vh - 100px);
    overflow: auto;

    .theme-network-bar-site {
      display: block;
      text-decoration: none;
      color: choose-contrast-color($nav-network-dropdown-item-background-color);
      font-size: $nav-network-site-font-size;
      line-height: $nav-network-dropdown-item-line-height;
      padding: $nav-network-dropdown-item-padding;
      background-color: $nav-network-dropdown-item-background-color;
      transition: background-color $transition-linear;
      &:hover {
        background-color: $nav-network-dropdown-item-background-color-hover;
      }
    }
    .theme-network-bar-site-logo { display: none; }
  }
}

.has-mobile-nav {
  .theme-network-bar,
  .theme-network-bar-network-title{
    display: none;
  }
}