$component-cta-1-overlay-color: $cta-element-overlay-color;
$component-cta-1-overlay-effect: multiply;

.premier-cta-option-1{
  &.sn-variation-default {
    position: relative;
    align-items: flex-end;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: $component-cta-1-overlay-color;
      z-index: 0;
      mix-blend-mode: $component-cta-1-overlay-effect;
    }
  }
}
