// Headline
[premier-hero-slider="2"] {
  .hero-call-to-action-text {
    position: relative;
    margin-right: $component-hero-ms-secondary-accent-width + $desktop-padding; // 50px;
    background-color: $component-hero-ms-secondary-content-background-color;
    padding: $component-hero-ms-secondary-content-padding;
    max-width: #{$component-hero-max-width / 2};
    &:after{
      content: "\f0da";
      width: $component-hero-ms-secondary-accent-width;
      background-color: $component-hero-ms-secondary-accent-background-color;
      color: $component-hero-ms-secondary-accent-text-color;
      font-size: $component-hero-ms-secondary-accent-text-size;
      height: 100%;
      font-family: 'fontawesome';
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -#{$component-hero-ms-secondary-accent-width + $component-hero-ms-secondary-accent-space};
    }
    .slide-title{
      margin: 0;
      text-shadow: none;
      font-family: $component-hero-ms-secondary-title-font;
      font-size: $component-hero-ms-secondary-title-text-size;
      @media screen and (min-width: 500px) and (max-width: $breakpoint-sm-max) {
        font-size: $component-hero-ms-secondary-title-text-size-md;
      }
      @media screen and (max-width: 500px) {
        font-size: $component-hero-ms-secondary-title-text-size-sm;
      }
      color: $component-hero-ms-secondary-title-text-color;
      line-height: $component-hero-ms-secondary-title-line-height;
      font-weight: $component-hero-ms-secondary-title-text-weight;
      text-transform: $component-hero-ms-secondary-title-text-case;
    }
    .slide-description{
      margin: 0;
      text-shadow: none;
      font-family: $component-hero-ms-secondary-description-font;
      font-size: $component-hero-ms-secondary-description-text-size;
      @media screen and (min-width: 500px) and (max-width: $breakpoint-sm-max) {
        font-size: $component-hero-ms-secondary-description-text-size-md;
      }
      @media screen and (max-width: 500px) {
        font-size: $component-hero-ms-secondary-description-text-size-sm;
      }
      color: $component-hero-ms-secondary-description-text-color;
      line-height: $component-hero-ms-secondary-description-line-height;
      font-weight: $component-hero-ms-secondary-description-text-weight;
      text-transform: $component-hero-ms-secondary-description-text-case;
    }
    .slide-title + .slide-description{
      margin: .1em 0 0 0;
    }
  }
}

// Content Width
[premier-hero-slider="2"] {
  @include hero-cta-content-width-content;
}

// Image Width
[premier-hero-slider="2"] {
  @include hero-slider-image-width-content;
}

// Container Style
[premier-hero-slider="2"] {
  background-color: #1f1f1f;
  background-image: url($premier-media-path+"images/premier-dark-pattern.jpg");
  background-repeat: repeat;
  background-position: center center;
}

// CTAs
[premier-hero-ctas="2"] {
  @extend .full-width-container;
  @include hero-ctas-secondary;
}

// Mobile View
[premier-hero-slider="2"] {
  @include hero-ms-mobile-spacing;
}