#networkNavMenuTitle {
  width: 100%;
  border-bottom: none;
  .theme-nav-title-text { padding-right: $slide-nav-title-height; }
}

#networkNavMenuSites {
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  z-index: 101;
  transition: left $transition-linear;
  display: flex;
  flex-flow: column;
  background-color: $slide-nav-background-color;

  .network-nav-expanded & { left: 0; }
  .theme-nav-forward{
    color: $slide-nav-back-color;
  }
}