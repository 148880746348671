
// Visually Hide Something
// https://a11yproject.com/posts/how-to-hide-content/
@mixin visually-hidden{
  position: absolute !important;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

@mixin import-google-fonts($family-primary: $font-primary, $family-secondary: $font-secondary) {
  $gf-primary-subset: null;
  $gf-secondary-subset: null;
  $divider: '|';
  @if $family-primary == $family-secondary or $family-secondary == null {
    $family-secondary: '';
    $divider: '';
  }
  $gf-primary: str-replace($family-primary, ' ', '+');
  $gf-secondary: str-replace($family-secondary, ' ', '+');
  @if $family-primary == 'Source Sans Pro' { $gf-primary-subset: ':400,400i,700,700i,900,900i'; }
  @if $family-secondary == 'Lato' { $gf-secondary-subset: ':400,400i,700,700i,900,900i'; }
  @if $family-secondary == 'Oswald' { $gf-secondary-subset: ':400,700'; }
  @if $family-secondary == 'Roboto Slab' { $gf-secondary-subset: ':400,700'; }
  @import url("https://fonts.googleapis.com/css?family=#{$gf-primary}#{$gf-primary-subset}#{$divider}#{$gf-secondary}#{$gf-secondary-subset}");
}