// Global
$slide-nav-theme-color: $color-primary !default;
$slide-nav-font-family: $font-primary;
$slide-nav-font-size: 14px;
$slide-nav-text-case: uppercase;
$slide-nav-text-align: center;
$slide-nav-text-weight: bold;
$slide-nav-text-color: choose-contrast-color($main-nav-band-background-color);
$slide-nav-border-color: rgba(127, 127, 127, 0.075);
$slide-nav-background-color: rgba(black ,0.9);
$slide-nav-tablet-width: 400px;

// Nav Items
$slide-nav-item-background-color-disabled: transparent;
$slide-nav-item-background-color-disabled-hover: $slide-nav-theme-color;
$slide-nav-item-background-color-disabled-selected: rgba(255,255,255,0.1);
$slide-nav-item-text-color-disabled: #999;
$slide-nav-item-text-color-disabled-hover: #999;
$slide-nav-item-text-color-disabled-selected: #999;

$slide-nav-item-border: 1px solid rgba(255,255,255,0.1); ///
$slide-nav-item-height: 44px; ///

$slide-nav-link-text-background-color: transparent;
$slide-nav-link-text-background-color-hover: $slide-nav-theme-color;
$slide-nav-link-text-background-color-selected: rgba(255,255,255,0.15);
$slide-nav-link-text-color: choose-contrast-color($slide-nav-background-color);
$slide-nav-link-text-color-hover: choose-contrast-color($slide-nav-link-text-background-color-hover);
$slide-nav-link-text-color-selected: $slide-nav-link-text-color;
$slide-nav-link-text-align: left;
$slide-nav-link-line-height: 1.25;
$slide-nav-link-padding: 12px 20px;

$slide-nav-title-background-color: $slide-nav-theme-color;
$slide-nav-title-text-color: choose-contrast-color($slide-nav-title-background-color);
$slide-nav-title-text-weight: 400;
$slide-nav-title-text-align: left;
$slide-nav-title-padding: 12px 20px;
$slide-nav-title-line-height: 1.25;
$slide-nav-title-case: uppercase;

$slide-nav-title-border: 1px solid rgba(255,255,255,0.1);
$slide-nav-title-height: 44px;

// Back Forward
$slide-nav-direction-font-size: $slide-nav-font-size * 0.625;
$slide-nav-direction-font-family: "FontAwesome";

$slide-nav-back-icon: "\f053";
$slide-nav-back-background-color: $slide-nav-theme-color;
$slide-nav-back-background-color-hover: $slide-nav-theme-color;
$slide-nav-back-color: choose-contrast-color($slide-nav-back-background-color);
$slide-nav-back-color-hover: choose-contrast-color($slide-nav-back-background-color-hover);
$slide-nav-back-border: 1px solid rgba(255,255,255,0.05); // seems to double up when transparent

$slide-nav-forward-icon: "\f054";
$slide-nav-forward-background-color: transparent;
$slide-nav-forward-background-color-hover: $slide-nav-theme-color;
$slide-nav-forward-color: choose-contrast-color($slide-nav-background-color);
$slide-nav-forward-color-hover: choose-contrast-color($slide-nav-forward-background-color-hover);
$slide-nav-forward-border: 1px solid rgba(255,255,255,0.05); // seems to double up when transparent

// Content and Menus
$slide-nav-content-spacing: 10px; ///
$slide-nav-content-background-color: $slide-nav-theme-color;
$slide-nav-menu-background-color: transparent;
$slide-nav-menu-ios-chrome-fix: 72px; // Add extra spacing for ios chrome collapse.

// Toggle
$slide-nav-toggle-background-color-open: transparent;
$slide-nav-toggle-background-color-closed: transparent;
$slide-nav-toggle-background-color-open-hover: null;
$slide-nav-toggle-background-color-closed-hover: null;

$slide-nav-toggle-icon-font: "FontAwesome";
$slide-nav-toggle-icon-char: "\f0c9";
$slide-nav-toggle-icon-color-open: choose-contrast-color($slide-nav-content-background-color);
$slide-nav-toggle-icon-color-open-hover: null;
$slide-nav-toggle-icon-color-closed: choose-contrast-color($mobile-nav-background-color);
$slide-nav-toggle-icon-color-closed-hover: null;

$slide-nav-toggle-area-width: $slide-nav-title-height;
$slide-nav-toggle-area-height: $slide-nav-title-height;

$slide-nav-toggle-font-size: $slide-nav-font-size * 1.25;
$slide-nav-toggle-z-index: 100;

// Overlay
$slide-nav-overlay-background-color: white;

// Search
$slide-nav-search-content-height: 28px;
$slide-nav-search-padding: 10px;

$slide-nav-search-input-font-size: $slide-nav-font-size;
$slide-nav-search-input-background: rgba(255,255,255,0.8);
$slide-nav-search-input-background-focus: rgba(255,255,255,1);
$slide-nav-search-input-color: choose-contrast-color($slide-nav-search-input-background);

$slide-nav-search-submit-font-size: 12px;
$slide-nav-search-submit-background: darken($slide-nav-theme-color, 10%);
$slide-nav-search-submit-background-focus: darken($slide-nav-theme-color, 20%);
$slide-nav-search-submit-color: choose-contrast-color($slide-nav-search-submit-background);

$slide-nav-search-icon-text-size: $slide-nav-search-input-font-size;
$slide-nav-search-icon-font: "Fontawesome";
$slide-nav-search-icon-char: "✕";
$slide-nav-search-icon-color: $slide-nav-search-input-color;



@mixin slide-nav-item-state-base{
  .theme-nav-link,
  .theme-nav-forward,
  .theme-nav-back{
    transition:
      background-color $transition-linear,
      color $transition-linear;
  }
  .theme-nav-link{
    color: $slide-nav-link-text-color;
    background-color: $slide-nav-link-text-background-color;
  }
  .theme-nav-back {
    color: $slide-nav-back-color;
    background-color: $slide-nav-back-background-color;
  }
  .theme-nav-forward {
    color: $slide-nav-forward-color;
    background-color: $slide-nav-forward-background-color;
  }
  
}
@mixin slide-nav-item-state-hover{
  .theme-nav-link:hover {
    color: $slide-nav-link-text-color-hover;
    background-color: $slide-nav-link-text-background-color-hover;
  }
  .theme-nav-back:hover {
    color: $slide-nav-back-color-hover;
    background-color: $slide-nav-back-background-color-hover;
  }
  .theme-nav-forward:hover {
    color: $slide-nav-forward-color-hover;
    background-color: $slide-nav-forward-background-color-hover;
  }
}
@mixin slide-nav-item-state-selected{
  &.selected .theme-nav-link {
    color: $slide-nav-link-text-color-selected;
    background-color: $slide-nav-link-text-background-color-selected;
  }
}
@mixin slide-nav-item-state-disabled{
  &.disabled{
    .theme-nav-link,
    .theme-nav-forward,
    .theme-nav-back{
      color: $slide-nav-item-text-color-disabled;
      background-color: $slide-nav-item-background-color-disabled;
      &:hover {
        color: $slide-nav-item-text-color-disabled-hover;
        background-color: $slide-nav-item-background-color-disabled-hover;
      }
    }
  }
  &.disabled.selected .theme-nav-link {
    color: $slide-nav-item-text-color-disabled-selected;
    background-color: $slide-nav-item-background-color-disabled-selected;
  }
}
@mixin slide-nav-item-state-paywalled{
  &.paywalled {
    img {
      display: inline-block;
      padding-right: 5px;
      max-width: 40px;
      max-height: 20px;
    }
  }
}


@mixin slide-nav-open-close{
  .has-mobile-nav.slide-nav-open{
    overflow: hidden; // hide scroll bar
  }
  .theme-nav-style-slide {
    width: 100%;
    position: absolute;
    right: 100%;
    left: auto;
    transition: right $transition-linear;
    .slide-nav-open &{
      position: fixed;
      right: 0;
    }
    @media (min-width: 769px) {
      width: $slide-nav-tablet-width;
      .slide-nav-open & {
        right: calc(100vw - #{$slide-nav-tablet-width}) !important;
      }
      .slide-nav-open.page-manager-visible & {
        right: calc(100vw - #{$slide-nav-tablet-width} - #{$page-manager-width}) !important;
      }
    }
  }
}

// Resets
.theme-nav-style-slide {
  a:hover { text-decoration: none; }
}

@include slide-nav-open-close;

.theme-nav-style-slide {
  display: flex;
  flex-flow: column;
  height: 100vh;
  top: 0;
  background-color: $slide-nav-background-color;
  font-size: $slide-nav-font-size;
}

.theme-mobile-nav{
  .theme-slide-nav-content {
    background-color: $slide-nav-content-background-color;
    width: 100%;
  }
  .theme-nav-menus {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    width: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .theme-nav-menu {
    background-color: $slide-nav-menu-background-color;
    padding-bottom: $slide-nav-menu-ios-chrome-fix;
    transition: 
      left $transition-linear,
      opacity $transition-linear;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    padding: 0;
    list-style-type: none;
  }
  .theme-nav-parent-in,
  .theme-nav-parent-out {
    left: -10%;
    opacity: 0;
  }
  .theme-nav-children-in,
  .theme-nav-children-out {
    left: 100%;
  }
  .theme-nav-parent-out,
  .theme-nav-children-out {
    max-height: 100%;
    overflow: hidden;
  }

}

// Nav Item layout
.theme-mobile-nav{
  .theme-nav-item{
    display: flex;
    flex-flow: row;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
  }
  .theme-nav-link{
    flex-grow: 1;
  }
  .theme-nav-forward,
  .theme-nav-back{
    cursor: pointer;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .theme-nav-forward{
    width: $slide-nav-item-height;
    &:before { content: $slide-nav-forward-icon; }
  }
  .theme-nav-back{
    width: $slide-nav-title-height;
    &:before { content: $slide-nav-back-icon; }
  }
  .theme-nav-title{
    display: flex;
    flex-flow: row;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
  }
  .theme-nav-title-text{
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
// Nav Item theme
.theme-mobile-nav{
  .theme-nav-item {
    border-bottom: $slide-nav-item-border;
    padding: 0;
  }
  .theme-nav-link{
    cursor: pointer;
    padding: $slide-nav-link-padding;
    line-height: $slide-nav-link-line-height;
    text-align: $slide-nav-link-text-align;
  }
  .theme-nav-title{
    background-color: $slide-nav-title-background-color;
    border: $slide-nav-title-border;
  }
  .theme-nav-title-text {
    color: $slide-nav-title-text-color;
    font-weight: $slide-nav-title-text-weight;
    text-align: $slide-nav-title-text-align;
    padding: $slide-nav-title-padding;
    line-height: $slide-nav-title-line-height;
    text-transform: $slide-nav-title-case;
  }
  .theme-nav-forward,
  .theme-nav-back{
    font-family: $slide-nav-direction-font-family;
    font-size: $slide-nav-direction-font-size;
  }
  .theme-nav-back {
    border-right: $slide-nav-back-border;
  }
  .theme-nav-forward {
    border-left: $slide-nav-forward-border;
  }
}

// Nav Item States
.theme-mobile-nav{
  .theme-nav-title,
  .theme-nav-item{
    @include slide-nav-item-state-base;
    @include slide-nav-item-state-paywalled;
    @include slide-nav-item-state-hover;
    @include slide-nav-item-state-disabled;
    @include slide-nav-item-state-selected;
  }
}

// Toggle
.theme-slide-nav-toggle {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: $slide-nav-toggle-z-index;
  font-size: $slide-nav-toggle-font-size;
  line-height: $slide-nav-toggle-area-height;
  height: $slide-nav-toggle-area-height;
  width: $slide-nav-toggle-area-width;
  text-align: center;
  cursor: pointer;
  background-color: $slide-nav-toggle-background-color-closed;
  color: $slide-nav-toggle-icon-color-closed;
  transition:
    color $transition-linear,
    background-color $transition-linear;
  &:before {
    font-family: $slide-nav-toggle-icon-font;
    content: $slide-nav-toggle-icon-char;
  }
  &:hover {
    background-color: $slide-nav-toggle-background-color-closed-hover;
    color: $slide-nav-toggle-icon-color-closed-hover;
  }
  .has-mobile-nav &{
    display: block;
  }
  .slide-nav-open &{
    background-color: $slide-nav-toggle-background-color-open;
    color: $slide-nav-toggle-icon-color-open;
    &:hover{
      background-color: $slide-nav-toggle-background-color-open-hover;
      color: $slide-nav-toggle-icon-color-open-hover;
    }
  }
  // Apply padding to Mobile Nav elements to allow for toggle
  + .theme-nav-title,
  + .theme-slide-nav-content{
    > :first-child,
    > :first-child.theme-search-box + .theme-search-box{
      padding-right: $slide-nav-toggle-area-width;
    }
  }
}

// Overlay
.theme-slide-nav-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  width: 9999px;
  height: 100%;
  opacity: 0;
  background-color: $slide-nav-overlay-background-color;
  transition: opacity $transition-linear;
  .slide-nav-open &{
    display: block;
    opacity: .5;
  }
}

// Search
.theme-mobile-nav {
  .theme-search-box {
    .has-mobile-nav &{
      display: flex;
    }
    flex-flow: row;
    align-items: center;
    overflow: hidden;
    padding: $slide-nav-search-padding;
    &:not(:first-child) {
      border-top: $slide-nav-title-border;
    }
  }

  .theme-search-input,
  .theme-search-submit,
  .theme-search-clear {
    line-height: $slide-nav-search-content-height;
    transition: background-color $transition-linear;
  }

  .theme-search-input,
  .theme-search-clear { font-size: $slide-nav-search-input-font-size; }

  .theme-search-input {
    flex-grow: 1;
    border: 0;
    padding: 0 $slide-nav-search-padding;
    color: $slide-nav-search-input-color;
    background-color: $slide-nav-search-input-background;

    &::-ms-clear { display: none; }
    &:focus,
    &:focus + .theme-search-clear{
      outline: none;
      background-color: $slide-nav-search-input-background-focus;
    }
  }

  .theme-search-submit {
    cursor: pointer;
    text-align: center;
    padding: 0 $slide-nav-search-padding;
    font-size: $slide-nav-search-submit-font-size;
    color: $slide-nav-search-submit-color;
    background-color: $slide-nav-search-submit-background;

    &:focus,
    &:hover { background-color: $slide-nav-search-submit-background-focus; }
  }

  .theme-search-clear {
    text-decoration: none;
    text-align: center;
    padding: 0 $slide-nav-search-padding;
    height: $slide-nav-search-content-height; // Because 'inline' is being added via js
    background-color: $slide-nav-search-input-background;

    &:before {
      content: $slide-nav-search-icon-char;
      color: $slide-nav-search-icon-color;
      font-family: $slide-nav-search-icon-font;
      font-size: $slide-nav-search-icon-text-size;
    }
  }
}
