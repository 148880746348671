$component-footer-background-color: $color-secondary;
$component-footer-text-header-color: choose-contrast-color($component-footer-background-color);
$component-footer-text-body-color: choose-contrast-color($component-footer-background-color);
$component-footer-text-link-color: choose-contrast-color($component-footer-background-color);
$component-footer-text-link-color-hover: $color-primary;
$component-footer-text-font-family: $font-primary;
$component-footer-text-weight: 600;
$component-footer-text-header-font-family: null;

$component-footer-logo-max-height: 150px;


// Global Footer
// Edit mode UI
.custom-footer-extra{
  @include edit_ui_element($padding: 10px);
  &:empty{ display: none; }
}

// Typography
.custom-footer-extra .textBlockElement > h3{
  color: $component-footer-text-header-color;
  font-family: $component-footer-text-header-font-family;
}
.custom-footer-extra .textBlockElement .text{
  p, li, a{
    font-family: $component-footer-text-font-family;
    color: $component-footer-text-body-color;
  }
  p{
    margin-bottom: 0;
  }
  a{
    color: $component-footer-text-link-color;
    transition: color $transition-linear;
    &:hover{
      text-decoration: none;
      color: $component-footer-text-link-color-hover;
    }
  }
}

// Layout
.custom-footer-extra {
  @include global-cta;
  @include global-social;
  @include content-container-padding-vert(2);
  background-color: $component-footer-background-color;
  .layoutContainer{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    background-color: transparent;
  }
}
@media screen and (min-width: $breakpoint-md-min) and (max-width: 1199px){
  .col-custom-md-5{
    max-width: ( ($content-area-width - $content-area-padding) * (5/10) );
    width: 50%;
  }
  .col-custom-md-10{
    max-width: ($content-area-width - $content-area-padding);
    width: 100%;
  }
}
@media screen and (min-width: 1200px){
  .col-custom-lg-2{
    max-width: ( ($content-area-width - $content-area-padding) * (2/10) );
    width: 20%;
  }
  .col-custom-lg-10{
    max-width: ($content-area-width - $content-area-padding);
    width: 100%;
  }
}

// Logo
.premier-footer-logo-img{
  @include page-element-margin;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: auto;
  max-height: $component-footer-logo-max-height;
  max-width: 100%;
}
.premier-footer-logo-link{
  display: contents;
}

// Option 1
.premier-footer-1{
  .custom-link-list{
    text-align: center;
  }
  .custom-footer-link{
    display: inline-block;
    margin: 3px 15px;
    font-weight: $component-footer-text-weight;
    font-family: $component-footer-text-font-family;
    color: $component-footer-text-link-color;
    transition: color $transition-linear;
    &:hover{
      text-decoration: none;
      color: $component-footer-text-link-color-hover;
    }
  }
}

// Option 2
.premier-footer-2{
  @media screen and (max-width: 1199px){
    .textBlockElement,
    .textBlockElement > h3{
      text-align: center !important;
    }
  }
  @media screen and (min-width: 1200px){
    .social-media-links .sn-social-media-list{
      justify-content: flex-start;
    }
  }
}

// Option 3
.premier-footer-3{
  .textBlockElement,
  .textBlockElement > h3{
    text-align: center !important;
  }
  .column{
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  @media screen and (max-width: $breakpoint-sm-max){
    .column-2{
      order: -1;
    }
  }
}