// Site Logo
// 1. Settings
// 2. Styles


// External Variables (Un-comment these if you are having trouble with missing variables)
// $main-nav-width-max: $content-area-width !default;


// 1. Settings
$site-logo-flex-alignment-items-y: center !default; // top bottom or center;
$site-logo-attachment-origin: 'main' !default; // main or nav
$site-logo-size: $site-tagline-height + $main-nav-height + $sub-nav-height !default;
$site-logo-size-fixed: $site-tagline-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed !default;
$site-logo-width-desktop: $site-logo-size !default;
$site-logo-height-desktop: $site-logo-size !default;
$site-logo-width-desktop-fixed: $site-logo-size-fixed !default;
$site-logo-height-desktop-fixed: $site-logo-size-fixed !default;
$site-logo-width-mobile: 60px !default;
$site-logo-height-mobile: 60px !default;
$site-logo-padding: 5px !default;
$site-logo-offset: $site-logo-width-desktop !default;
$site-logo-offset-fixed: $site-logo-width-desktop-fixed !default;


// 2. Styles
.has-main-nav{
  .topNav{
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  #sn-site-logo{
    height: 100%;
    margin: auto;
    align-self: center;
    display: flex;
    align-items: $site-logo-flex-alignment-items-y;
    pointer-events: none;
    .sn-site-logo-wrap{
      pointer-events: initial;
    }
  }
  @if $site-logo-attachment-origin == 'main' and $premier-navigation-option != 3 {
    #sn-site-logo {
      transition: padding $transition-linear;
      padding-top: $nav-title-bar-height;
    }
    &.nav-fixed #sn-site-logo{
      padding-top: $nav-title-bar-height-fixed;
    }
    &.has-sub-nav #sn-site-logo{
      padding-bottom: $sub-nav-height;
    }
    &.nav-fixed.has-sub-nav #sn-site-logo {
      padding-bottom: $sub-nav-height-fixed;
    }
    @if $sub-nav-hide-on-home == true{
      &.has-sub-nav #home #sn-site-logo,
      &.has-sub-nav .home #sn-site-logo{
        padding-bottom: 0;
      }
      &.nav-fixed.has-sub-nav #home #sn-site-logo,
      &.nav-fixed.has-sub-nav .home #sn-site-logo {
        padding-bottom: 0;
      }
    }

  }

  #sn-site-logo{
    position: absolute;
    width: 100%;
    max-width: $content-area-width;
    // height: 0px;
    z-index: 1;
    // top: 0;

    .sn-site-logo-wrap{
      display: table;
      table-layout: fixed;
      width: $site-logo-width-desktop;
      height: $site-logo-height-desktop;
      transition: all $transition-linear;
      padding: $site-logo-padding;
      box-sizing: border-box;
    }
    .sn-site-logo-background{
      display: table-cell;
      vertical-align: middle;
      overflow: hidden;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  // Fixed Logo Size
  .nav-fixed{
    #sn-site-logo{
      .sn-site-logo-wrap{
        width: $site-logo-width-desktop-fixed;
        height: $site-logo-height-desktop-fixed;
      }
    }
  }
}


.has-mobile-nav{
  .sn-site-logo-wrap{
    width: $site-logo-width-mobile;
    height: $site-logo-height-mobile;
    display: block;
  }
  .sn-site-logo-background{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    display: block;
  }
}
