// The following CSS is added inline, into the DOM
// The values here are controlled thru the ACP: Theme Settings - Background
// .site-background {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: -1;
//   background-color: {ACP Page Background Color};
//   background-position: {ACP Background Image Position};
//   background-attachment: {ACP Background Image Attachment};
//   background-repeat: {ACP Background Image Repeat};
//   background-image: url({ACP Background Image url});
// }

#siteContainer{
  position: relative; // We are injecting the site background into the site container, so this needs to be relative
}
