// Header Option 2

// Title Bar Options
$nav-title-bar-height: 40px;
$nav-title-bar-height-fixed: .01px;
$nav-title-bar-band-background-color: white;
$nav-title-bar-text-color: choose-contrast-color($nav-title-bar-band-background-color);
$nav-title-bar-text-color-mobile: choose-contrast-color($mobile-nav-background-color);
$nav-title-bar-font-family: $font-secondary;
$nav-title-bar-font-size: 11px;
$nav-title-bar-line-height: 1;
$nav-title-bar-text-case: uppercase;
$nav-title-bar-text-weight: 700;
// Main Navigation Options
$main-nav-width-max: $content-area-width;
$main-nav-height: 60px;
$main-nav-height-fixed: 60px;
$main-nav-band-background-color: $color-primary;
$main-nav-band-background-color-secondary: $color-secondary;
$main-nav-flex-alignment: flex-start;
$main-nav-item-padding: 10px;
$main-nav-cascade-styles: true;
$main-nav-child-indicator: false;
$main-nav-child-indicator-cascade-styles: false;
$main-nav-more-plus-buffer: true;
$main-nav-font-family: $font-secondary;
$main-nav-font-size: 14px;
$main-nav-text-case: uppercase;
$main-nav-text-align: center;
$main-nav-text-weight: bold;
$main-nav-text-color: choose-contrast-color($main-nav-band-background-color);
$main-nav-text-color-hover: null;
$main-nav-text-color-selected: null;
$main-nav-text-color-disabled: $main-nav-text-color;
$main-nav-item-background-color: transparent;
$main-nav-item-background-color-hover: transparent;
$main-nav-item-background-color-selected: transparent;
$main-nav-item-background-color-disabled: rgba(lightgray, .5);
// Dropdown Navigation Options
$dropdown-nav-min-width: 200px;
$dropdown-nav-min-height: 35px;
$dropdown-nav-transition-distance: 20px;
$dropdown-nav-item-padding: $main-nav-item-padding;
$dropdown-nav-cascade-styles: true;
$dropdown-nav-child-indicator: true;
$dropdown-nav-child-indicator-cascade-styles: false;
$dropdown-nav-container-background-color: #ededed;
$dropdown-nav-container-border-size: 0px;
$dropdown-nav-container-border: $dropdown-nav-container-border-size solid transparent;
$dropdown-nav-text-align: left;
$dropdown-nav-text-case: none;
$dropdown-nav-text-weight: normal;
$dropdown-nav-font-family: $font-secondary;
$dropdown-nav-font-size: 14px;
$dropdown-nav-line-height: 1.2;
$dropdown-nav-text-color: choose-contrast-color($dropdown-nav-container-background-color);
$dropdown-nav-text-color-hover: null;
$dropdown-nav-text-color-selected: null;
$dropdown-nav-text-color-disabled: darken($dropdown-nav-container-background-color, 20%);
$dropdown-nav-item-background-color: transparent;
$dropdown-nav-item-background-color-hover: darken($dropdown-nav-container-background-color, 5%); //$main-nav-item-background-color-hover;
$dropdown-nav-item-background-color-selected: darken($dropdown-nav-container-background-color, 5%); //$dropdown-nav-item-background-color-hover;
$dropdown-nav-item-background-color-disabled: transparent;
$dropdown-nav-2-container-background-color: $dropdown-nav-container-background-color;
$dropdown-nav-2-container-border-size: null;
$dropdown-nav-2-container-border: null;
$dropdown-nav-2-text-align: null;
$dropdown-nav-2-text-case: null;
$dropdown-nav-2-text-weight: null;
$dropdown-nav-2-font-family: null;
$dropdown-nav-2-font-size: null;
$dropdown-nav-2-line-height: null;
$dropdown-nav-2-text-color: null;
$dropdown-nav-2-text-color-hover: null;
$dropdown-nav-2-text-color-selected: null;
$dropdown-nav-2-text-color-disabled: null;
$dropdown-nav-2-item-background-color: null;
$dropdown-nav-2-item-background-color-hover: null;
$dropdown-nav-2-item-background-color-selected: null;
$dropdown-nav-2-item-background-color-disabled: null;
// Sub Navigation Options
$sub-nav-hide-on-home: true;
$sub-nav-more-plus-buffer: true;
$sub-nav-band-background-color: #ededed;
$sub-nav-font-family: $font-secondary;
$sub-nav-font-size: 14px;
$sub-nav-text-color: choose-contrast-color($sub-nav-band-background-color);
$sub-nav-text-color-hover: null;
$sub-nav-text-color-disabled: darken($sub-nav-band-background-color, 20%);
$sub-nav-item-background-color-disabled: transparent;
$sub-nav-height: 40px;
$sub-nav-height-fixed: 40px;
$sub-nav-item-background-color: transparent;
$sub-nav-item-background-color-hover: darken($sub-nav-band-background-color, 5%);
$sub-nav-item-text-case: none;
$sub-nav-item-text-weight: normal;
$sub-nav-item-padding: 10px;
$sub-nav-flex-alignment: flex-start;
// Sub Navigation Title Options
$sub-nav-title-font-family: $font-secondary;
$sub-nav-title-font-size: $sub-nav-font-size;
$sub-nav-title-height: $sub-nav-height;
$sub-nav-title-text-case: capitalize;
$sub-nav-title-text-weight: bold;
$sub-nav-title-background-color: transparent;
$sub-nav-title-text-color: choose-contrast-color($sub-nav-band-background-color);
$sub-nav-title-padding: 10px;
$sub-nav-title-divider-content: '\f105';
$sub-nav-title-divider-font: 'fontawesome';
// Sub Navigation Dropdown Options
$sub-nav-dropdown-padding: $sub-nav-item-padding;
$sub-nav-dropdown-font-family: $font-secondary;
$sub-nav-dropdown-font-size: $sub-nav-font-size;
$sub-nav-dropdown-text-case: none;
$sub-nav-dropdown-line-height: 1.2;
$sub-nav-dropdown-min-width: 200px;
$sub-nav-dropdown-min-height: 35px;
$sub-nav-dropdown-transition-distance: 20px;
$sub-nav-dropdown-container-background-color: $sub-nav-band-background-color;
$sub-nav-dropdown-container-border: 0;
$sub-nav-dropdown-text-align: left;
// Search (Desktop) Options
$site-search-height: 26px;
$site-search-spacing: 8px;
$site-search-border-width: 1px;
$site-search-input-font-size: 14px;
$site-search-input-color: #666;
$site-search-input-border-color: rgba(255, 255, 255, 0.1);
$site-search-input-background: rgba(255, 255, 255, 0.8);
$site-search-input-background-focus: rgba(255, 255, 255, 1);
$site-search-submit-color: #fff;
$site-search-submit-border-color: rgba(255, 255, 255, 0.1);
$site-search-submit-background: darken($color-primary, 10%);
$site-search-submit-background-focus: darken($color-primary, 20%);
$site-search-submit-font-size: 12px;
// Desktop Navigation Sizes
$desktop-nav-height: $nav-title-bar-height+$main-nav-height+$sub-nav-height;
$desktop-nav-height-fixed: $nav-title-bar-height-fixed+$main-nav-height-fixed+$sub-nav-height-fixed;
$desktop-nav-height-no-subnav: $nav-title-bar-height+$main-nav-height;
$desktop-nav-height-no-subnav-fixed: $nav-title-bar-height-fixed+$main-nav-height-fixed;
$nav-placeholder-height: $desktop-nav-height; // Remove after replacing these in code base
$nav-placeholder-height-fixed: $desktop-nav-height-fixed; // Remove after replacing these in code base
// Site Logo Options
$site-logo-flex-alignment-items-y: center; // top bottom or center;
$site-logo-attachment-origin: 'main'; // main or nav
$site-logo-size: $desktop-nav-height; // Remove after replacing these in code base
$site-logo-size-fixed: $desktop-nav-height-fixed; // Remove after replacing these in code base
$site-logo-width-desktop: 110px;
$site-logo-height-desktop: 102px; // $main-nav-height + 50px;
$site-logo-width-desktop-fixed: 65px;
$site-logo-height-desktop-fixed: $main-nav-height;
$site-logo-width-mobile: 60px;
$site-logo-height-mobile: 60px;
$site-logo-padding: 5px;
$site-logo-offset: $site-logo-width-desktop;
$site-logo-offset-fixed: $site-logo-width-desktop-fixed;

// Fix logo placement between fixed/normal
#sn-site-logo {
  .has-main-nav & {
    box-sizing: border-box;
    transition: padding $transition-linear;
    padding-top: $nav-title-bar-height;
  }
  .has-main-nav.nav-fixed & {
    padding-top: $nav-title-bar-height-fixed;
  }
  .has-main-nav.has-sub-nav & {
    padding-bottom: $sub-nav-height;
  }
  .has-main-nav.has-sub-nav.nav-fixed & {
    padding-bottom: $sub-nav-height-fixed;
  }
}

// Fix Nav log flex padding
.nav-fixed .has-site-logo .theme-main-nav .theme-nav{
  padding-right: 0 !important;
}
.has-site-logo .theme-main-nav .theme-nav .theme-nav-item:last-child {
  transition: margin $transition-linear;
  margin-right: 0;
}
.nav-fixed .has-site-logo .theme-main-nav .theme-nav .theme-nav-item:last-child {
  margin-right: $site-logo-offset - $site-logo-offset-fixed;
}

.theme-main-nav .custom-main-nav-extra{
  $main-nav-band-background-color-clean: color-to-rgb($main-nav-band-background-color);
  padding-left: 0 !important;
  background-color: $main-nav-band-background-color-secondary;
  &:before{
    content: "";
    width: $main-nav-height;
    transition: width $transition-linear;
    .nav-fixed &{
      width: $main-nav-height-fixed;
    }
    height: 100%;
    display: block;
    background-image:
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' preserveAspectRatio='none'%3E%3Cpolygon fill='#{$main-nav-band-background-color-clean}' points='0,10 0,0 10,0'/%3E%3C/svg%3E");
  }
  &:after{
    content: "";
    width: 100%;
    max-width: calc(50% - #{$content-area-width/2});
    height: 100%;
    background-color: $main-nav-band-background-color-secondary;
    position: absolute;
    top: 0;
    right: 0;
  }
}

// Global Items
.theme-main-nav .custom-main-nav-extra {
  @include global-social;
  @include global-cta;
  @include global-cta-image;
}
.custom-mobile-nav-extra{
  @include global-social;
  @include global-cta;
  @include global-cta-image;
}

.site-tagline-text{
  opacity: 0;
  transition: opacity $transition-linear;
  .has-site-title &{
    opacity: 1;
  }
}
.has-main-nav.has-network-nav{
  .site-tagline-text{
    display: none;
  }
}
.has-mobile-nav{
  #sn-site-logo{
    display: none;
  }
}
.has-mobile-nav{
  .site-tagline-text{ color: $nav-title-bar-text-color-mobile; }
}