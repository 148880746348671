.surveyLinkElement{
  a{
    @include global-link-style;
    @include global-link-container;
    @include global-link-text($align: center);
    @include global-link-icon-style($align: center);
    @include global-link-icon-character;
    small{
      font-size: 1em;
      font-weight: normal;
    }
  }
}
