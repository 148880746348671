// Slider Aspect Ratios
// "21:9" Ultra-Wide
// "16:9" Wide
// "4:3" Standard

// Hero Split
$component-hero-split-percent: 33.333%;
$component-hero-max-width: $content-area-width;

// Media Slider Headline Primary
$component-hero-ms-primary-title-font: $font-secondary;
$component-hero-ms-primary-title-text-size: 75px;
$component-hero-ms-primary-title-text-size-md: 49px;
$component-hero-ms-primary-title-text-size-sm: 26px !important;
$component-hero-ms-primary-title-text-color: white;
$component-hero-ms-primary-title-line-height: 1;
$component-hero-ms-primary-title-text-weight: 700;
$component-hero-ms-primary-title-text-case: uppercase;

$component-hero-ms-primary-description-font: null;
$component-hero-ms-primary-description-text-size: null;
$component-hero-ms-primary-description-text-size-md: 21px;
$component-hero-ms-primary-description-text-size-sm: 16px !important;
$component-hero-ms-primary-description-text-color: white;
$component-hero-ms-primary-description-line-height: null;
$component-hero-ms-primary-description-text-weight: null;
$component-hero-ms-primary-description-text-case: null;

$component-hero-ms-primary-accent-width: 120px;
$component-hero-ms-primary-accent-height: 10px;
$component-hero-ms-primary-accent-background-color: $color-primary;

// Media Slider Headline Secondary
$component-hero-ms-secondary-title-font: $font-secondary;
$component-hero-ms-secondary-title-text-size: 48px;
$component-hero-ms-secondary-title-text-size-md: 37px;
$component-hero-ms-secondary-title-text-size-sm: 26px !important;
$component-hero-ms-secondary-title-text-color: $color-primary;
$component-hero-ms-secondary-title-line-height: 1;
$component-hero-ms-secondary-title-text-weight: 700;
$component-hero-ms-secondary-title-text-case: uppercase;

$component-hero-ms-secondary-description-font: null;
$component-hero-ms-secondary-description-text-size: 16px;
$component-hero-ms-secondary-description-text-size-md: 16px;
$component-hero-ms-secondary-description-text-size-sm: 16px !important;
$component-hero-ms-secondary-description-text-color: $color-gray-dk;
$component-hero-ms-secondary-description-line-height: 1.2;
$component-hero-ms-secondary-description-text-weight: 400;
$component-hero-ms-secondary-description-text-case: null;

$component-hero-ms-secondary-accent-width: 30px;
$component-hero-ms-secondary-accent-space: 10px;
$component-hero-ms-secondary-accent-text-size: null;
$component-hero-ms-secondary-accent-background-color: $color-primary;
$component-hero-ms-secondary-accent-text-color: $color-secondary;
$component-hero-ms-secondary-content-padding: 10px;
$component-hero-ms-secondary-content-background-color: white;

// Media Slider Overlay Options
$component-hero-ms-image-overlay-gradient-opacity: 0.75;
$component-hero-ms-image-overlay-gradient-color: linear-gradient(to top, $color-primary, transparent 52%);
$component-hero-ms-image-overlay-gradient-dark: linear-gradient(to bottom, transparent 67%, black 100%);

$component-hero-ms-image-overlay-color: #4A4A4A;
$component-hero-ms-image-overlay-opacity: 0.3;
$component-hero-ms-image-overlay-blend-mode: multiply;

// CTA Section
$component-hero-cta-background-color: $color-secondary;
$component-hero-cta-title-size-md: 22px;
$component-hero-cta-title-size-sm: 18px;
$component-hero-cta-margin: 15px;


@mixin hero-overlay(){
  .media-wrapper {
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      pointer-events: none;
    }
  }
}
@mixin hero-cta-content-width-content(){
   .hero-call-to-action-container{
    left: 50%;
    transform: translateX(-50%);
    max-width: $component-hero-max-width;
  } 
}
@mixin hero-cta-content-width-full(){
  .hero-call-to-action-container{
    left: auto;
    transform: none;
    max-width: 100%;
  }
  .slides .slide .slide-overlay {
    left: auto;
    transform: none;
    max-width: 100%;
  }
}
@mixin hero-slider-image-width-content(){
  .slider{
    width: 100%;
    max-width: $component-hero-max-width;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@mixin hero-layout-cta-split(){
  .hero-call-to-action-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    pointer-events: none;
    .sn-call-to-action {
      margin: $component-hero-cta-margin 0;
    }
  }
  .hero-call-to-action-content{
    width: $component-hero-split-percent;
    padding: 0 30px 0 $desktop-padding;
    display: flex;
    flex-flow: column;
    justify-content: center;
    pointer-events: auto;
  }
}
@mixin hero-layout-cta-column($color){
  .hero-call-to-action-content{
    background-color: $color;
    display: flex;
    flex-flow: column;
    padding: $mobile-padding;
    .sn-call-to-action {
      margin: $mobile-padding;
    }
  }
}
@mixin hero-ms-mobile-spacing{
  .sn-breakpoint-lt-500,
  .sn-breakpoint-lt-300 {
    .slide .slide-overlay{
      padding: 0;
    }
    .slide .slide-text{
      vertical-align: bottom;
      padding-left: 0;
      padding-right: 0;
    }
    .hero-call-to-action-text{
      margin-right: 0;
      &:after{
        display: none;
      }
    }
  }
}
@mixin hero-ctas-secondary{
  background-color: $component-hero-cta-background-color;
  padding-top: $desktop-padding;
  padding-bottom: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-top: $mobile-padding;
    padding-bottom: $mobile-padding;
  }
}

// Resets
.premier-hero-slider{
  .flex-direction-nav,
  .slider-pagination{
    display: none !important;
  }
}

// Slider Container
.premier-hero-container {
  @extend .full-width;
  @extend .collapse-padding;
  @extend .compact;
}

// Slider
.premier-hero-slider{
  .slider{ margin: 0 !important;}
  .slide-text{
    padding-right: #{$desktop-padding * 2};
    padding-left: #{$desktop-padding * 2};
  }
  .slides .slide .slide-overlay {
    padding: 5% 0 5% 0;
  }
}

